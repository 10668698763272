export default class extends Controller {
  static targets = ["card", "menu", "likeForm", "reaction"];
  static values = { url: String };

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("d-none");
    } else {
      fetch(this.urlValue)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html);

        this.element.appendChild(fragment);
      });
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("d-none");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }

  qa_like_show(){
    this.menuTarget.classList.remove("d-none");
    this.menuTarget.classList.add("animate__fadeIn");
  }

  qa_like_hide() {
    this.menuTarget.classList.add("d-none");
    this.menuTarget.classList.remove("animate__fadeIn");
  }

  react(event) {
    const reaction = event.currentTarget.dataset.reaction;
    this.reactionTarget.value = reaction;
    $(event.currentTarget).tooltip('hide');
    this.likeFormTarget.requestSubmit();
  }
}
