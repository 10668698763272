export default class extends Controller {
  static targets = ["messages", "scrollButton", "newMessageButton", "targetcharcount"]

  connect() {
    this.hasMoreMessages = true
    this.isUserAtBottom = true
    this.loadingOlderMessages = false;
    this.newMessageCount = 0;
    this.scrollToBottom();

    const config = { childList: true, subtree: true };
    this.observer = new MutationObserver(this.newMessageReceived.bind(this));
    this.observer.observe(this.messagesTarget, config);
  }

  disconnect() {
    this.observer.disconnect();
  }

  newMessageReceived(mutationsList) {
    const hasNewMessage = mutationsList.some(mutation => {
      return Array.from(mutation.addedNodes).some(node => {
        return node.nodeType === 1 && !node.classList.contains('scroll-to-bottom');
      });
    });

    if(hasNewMessage){
    if (!this.isUserAtBottom) {
      if (!this.loadingOlderMessages) {
        this.updateNewMessageButtonText();
        this.hideScrollButton(); 
        this.shownewMessageButton();
      }
    } else {
      this.scrollToBottom();
    }
  }
  }

  updateNewMessageButtonText() {
    this.newMessageCount += 1;
      this.newMessageButtonTarget.textContent = `  (${this.newMessageCount}) New Message`;
  }

  scrollToBottom() {
    const element = this.messagesTarget;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
    this.isUserAtBottom = true;
    this.newMessageCount = 0;
    this.hideScrollButton();
    this.hidenewMessageButton();
  }

  loadChatMessages(){
    const element = this.messagesTarget;
    this.checkScrollPosition();
    if (element.scrollTop === 0 && this.hasMoreMessages) {
      const nextPage = parseInt(this.element.dataset.currentPage) + 1;
      const guest_id = this.element.dataset.guestId;
      const url =`/previous_guest_chats/?page=${nextPage}&guest_id=${guest_id}`
      this.fetch(url)
    }
  }

  loadMettingMessages(){
    const element = this.messagesTarget;
    this.checkScrollPosition();
    if (element.scrollTop === 0 && this.hasMoreMessages) {
      const nextPage = parseInt(this.element.dataset.currentPage) + 1;
      const meeting_id = this.element.dataset.meetingId;
      const url =`/meetings/${meeting_id}/previous_meeting_chats?page=${nextPage}`
      this.fetch(url)
    }
  }


  fetch(url) {
    this.loadingOlderMessages = true;
    const element = this.messagesTarget;
    const previousScrollHeight = element.scrollHeight
      if (url) {
        fetch(url, {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
        .then(response => {
          if (response.status === 204) {
          this.hasMoreMessages = false
        } 
          return response.text()
        } )
        .then(html => {
          this.element.insertAdjacentHTML("afterbegin", html);
          const newPage = parseInt(this.element.dataset.currentPage) + 1
          this.element.dataset.currentPage = newPage;
          requestAnimationFrame(() => {
          const newScrollHeight = element.scrollHeight;
          element.scrollTop = newScrollHeight - previousScrollHeight;
          this.loadingOlderMessages = false;
          })
        })
        .catch((error) => console.error("Error loading older messages:", error));
    }
  }

  checkScrollPosition() {
    const element = this.messagesTarget;
    const threshold = 100;  // Some buffer to account for pixel inaccuracies

    if (element.scrollHeight - element.scrollTop - element.clientHeight <= threshold) {
      this.isUserAtBottom = true; 
      this.hidenewMessageButton();
      this.hideScrollButton();
    } else {
      this.isUserAtBottom = false;
      this.showScrollButton();  
    }
  }

  showScrollButton() {
    this.scrollButtonTarget.classList.remove('d-none');
  }

  hideScrollButton() {
    this.scrollButtonTarget.classList.add('d-none');
  }

  shownewMessageButton() {
    this.newMessageButtonTarget.classList.remove('d-none');
  }

  hidenewMessageButton(){
    this.newMessageButtonTarget.classList.add('d-none');

  }

  charcterCounter(){
    document.querySelector('.send-text-char-counter').textContent = this.targetcharcountTarget.value.length;
  }
}


