export default class extends Controller {
  static values = { text: String };

  connect() {
    var element = this.element
    var link = this.textValue
    this.updateLink(link, element);
  }

  hasValidUrl(text){
    return (text.includes('https://') || text.includes('http://'))
  }

  updateLinkPreview(event){
    var element = document.getElementById('updateLinkpreview');
    var link = event.currentTarget.value;
    this.updateLink(link, element);
  }

  updateLink(link, element){
    if (this.hasValidUrl(link)) {
      fetch('/link_preview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: link
        })
      })
      .then((r) => r.text())
      .then((html) => {
        element.innerHTML = html
      });
    }
  }
}
