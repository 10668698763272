export default class extends Controller {

  search(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const value = event.target.value;
      const param = event.target.getAttribute('data-filter-query-param');
      const url = this.updateQueryParam(param, value)
      Turbo.visit(url.toString());
    }, 1000);
  }

  search_meetings(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const value = event.target.value;
      const params = event.target.getAttribute('data-filter-query-param').split('&');
      const searchParam = params[0];
      const typeParam = params[1];
      const guestParam = params[2];
      const typeValue = event.target.dataset.type;
      const guestValue = event.target.dataset.guestId;

      let meeting_params = this.updateMettingQueryParam(searchParam, value, window.location.href);
      meeting_params = this.updateMettingQueryParam(guestParam, guestValue, meeting_params);
      meeting_params = this.updateMettingQueryParam(typeParam, typeValue, meeting_params);
      const meeting_url = meeting_params.replace('guest_chats', 'meetings');
      fetch(meeting_url, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    }, 1000);
  }

  search_guests(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const value = event.target.value;
      const params = event.target.getAttribute('data-filter-query-param').split('&');
      const searchParam = params[0];
      const typeParam = params[1];
      const typeValue = event.target.dataset.type;

      let guest_params = this.updateMettingQueryParam(searchParam, value, window.location.href);
      guest_params = this.updateMettingQueryParam(typeParam, typeValue, guest_params);
      const guest_url = guest_params.replace('guest_chats', 'all_guests');
      fetch(guest_url, {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    }, 1000);
  }

  applyFilter(){
    let value;
    if (event.currentTarget.type == 'checkbox') {
      value = event.currentTarget.checked
    } else {
      value = event.currentTarget.value
    }
    const url = this.updateQueryParam(event.params.query, value)
    Turbo.visit(url.toString());
  }

  updateQueryParam(key, value){
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (value === "All" || value === '') {
          params.delete(key);
      } else {
          params.set(key, value);
      }
      url.search = params.toString();
      return url.toString();
  }
  updateMettingQueryParam(key, value, meeting_params){
      const url = new URL(meeting_params);
      const params = new URLSearchParams(url.search);
      if (value === "All" || value === '') {
          params.delete(key);
      } else {
          params.set(key, value);
      }
      url.search = params.toString();
      return url.toString();
  }

  clearFilters(){
    const url = new URL(window.location.href);
    if (url.search != ''){
      url.search = ''
      Turbo.visit(url.toString());
    }
  }

  clearHotelFilters(){
    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    params.delete('hotel_ids'); // Remove the 'hotel_ids' parameter

    url.search = params.toString();
    Turbo.visit(url.toString());
  }

  applySort(){
    var sort;
    if (event.currentTarget.dataset.sortDir === '') {
      if (event.currentTarget.dataset.target == 'completed-checklists'){
        sort = `${event.currentTarget.dataset.sortKey}:asc`;
      }else {
        sort = `${event.currentTarget.dataset.sortKey}:desc`;
      }
    } else if (event.currentTarget.dataset.sortDir.split(":")[1] === 'asc') {
         sort = `${event.currentTarget.dataset.sortKey}:desc`;
    } else {
         sort = `${event.currentTarget.dataset.sortKey}:asc`;
    }
    console.log(sort);
    const url = this.updateQueryParam('sort', sort)
    Turbo.visit(url.toString());
  }

  filterChats(){
    let value = event.currentTarget.dataset.value;

    const url = this.updateChatQueryParam(event.params.query, value)
    Turbo.visit(url.toString());
  }

  updateChatQueryParam(key, value){
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      if (value === "All" || value === '') {
          params.delete(key);
      } else {
          params.set(key, value);
      }
      url.search = params.toString();
      return url.toString();
  }

  clearBrandDropdown(){
    const group_id = event.currentTarget.value;
    fetch(`/admin/hotels/get_brands?group_id=${group_id}`, {
      method: 'GET',
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}
