export default class extends Controller{
  static targets = ["card"]
  static values = {
    qaForum: Number
  }

  initialize(){
    this.observer(this.cardTargets[this.cardTargets.length - 1])
  }

  observer(observeTarget) {
    const observer = new IntersectionObserver(entries => {
      var lastCard = entries[0]
      if (lastCard.isIntersecting) {
        observer.unobserve(lastCard.target);
        this.loadMore()
      } else {
        return
      }
    })
    observer.observe(observeTarget)
  }

  loadMore() {
    var next = document.querySelector('[rel=next]', this.paginationValue)
    if (!next) return;

    if (this.qaForumValue) {
      // Create a URL object from next.href
      let url = new URL(next.href);
    
      // Extract existing query params (e.g., ?page=2)
      let searchParams = url.search;
    
      // Build the new href with qaForumValue inserted into path
      var href = `/qa_forums/${this.qaForumValue}${searchParams}`;
    } else {
      var href = next.href;
    }

    fetch(href, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
