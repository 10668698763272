export default class extends Controller {
  static targets = ['pmcycletype', 'startdate', 'enddate', 'daterange', 'pmfilter',
                    'clearfilter', 'enddateText', 'calendarIcon', 'checkedroombox',
                    'checkedroomtext', 'submitButton']

  updateDate(){
    const pm_cycle_type = this.pmcycletypeTarget.value;
    const startDateValue = this.startdateTarget.value;
    var total_weeks;

    if (pm_cycle_type === 'quarterly') {
      total_weeks = 13;
    } else if (pm_cycle_type === 'semi_annualy') {
      total_weeks = 26;
    } else if (pm_cycle_type === 'annualy') {
      total_weeks = 52;
    } else {
      total_weeks = 0;
    }

    if (startDateValue && pm_cycle_type){
      const startDate = new Date(startDateValue);
      const endDate = new Date(startDate.getTime() + total_weeks * 7 * 24 * 60 * 60 * 1000);

      this.enddateTarget.value = endDate.toISOString().split("T")[0]
      this.calendarIconTarget.classList.remove('d-none')
      this.enddateTextTarget.innerText = endDate.toLocaleDateString('en-GB');
    }
  }
  showDateSelector(){
    if (event.currentTarget.dataset.value ==='custom_date' || event.currentTarget.value === 'custom_date'){
      this.daterangeTarget.classList.remove('d-none')
    }
    else{
      this.daterangeTarget.classList.add('d-none')
    }
  }

  showPmFilter(){
    this.pmfilterTarget.classList.remove('d-none')
    this.clearfilterTarget.classList.remove('d-none')
  }

  hideModal(){
    var modalElement = document.getElementById('popupModal');
    if (modalElement) {
      modalElement.classList.remove('show');
      var backdropElement = document.querySelector('.modal-backdrop');
      if (backdropElement) {
        backdropElement.parentNode.removeChild(backdropElement);
      }
    }
  }

  hideRoomModal(){
    var roomId = event.target.dataset.roomId;
    var modalElement = document.getElementById('roomModal' + roomId);
    if (modalElement) {
      modalElement.classList.remove('show');
      var backdropElement = document.querySelector('.modal-backdrop');
      if (backdropElement) {
        backdropElement.parentNode.removeChild(backdropElement);
      }
    }
  }

  printContent(event) {
    event.preventDefault();
    window.print();
  }

  selectRoom(event) {
    const roomElement = event.currentTarget;
    const checkbox = roomElement.querySelector(".room-checkbox");
  
    // Toggle the checkbox state
    checkbox.checked = !checkbox.checked;
  
    // Update styles based on selection
    if (checkbox.checked) {
      roomElement.classList.remove("bg-light");
      roomElement.classList.add("border", "border-dark", "bg-primary");
      const checkedRoomBox = roomElement.querySelector("[data-pmcycle-target='checkedroombox']");
      const checkedRoomText = roomElement.querySelector("[data-pmcycle-target='checkedroomtext']");
      if (checkedRoomBox) checkedRoomBox.classList.remove("d-none");
      if (checkedRoomText) {
        checkedRoomText.classList.remove("text-dark");
        checkedRoomText.classList.add("text-white");
      }
    } else {
      roomElement.classList.add("bg-light");
      roomElement.classList.remove("border", "border-dark", "bg-primary");
      const checkedRoomBox = roomElement.querySelector("[data-pmcycle-target='checkedroombox']");
      const checkedRoomText = roomElement.querySelector("[data-pmcycle-target='checkedroomtext']");
      if (checkedRoomBox) checkedRoomBox.classList.add("d-none");
      if (checkedRoomText) {
        checkedRoomText.classList.add("text-dark");
        checkedRoomText.classList.remove("text-white");
      }
    }
    // Update the "Select All" checkbox state
    this.updateSelectAllCheckbox();

    // Update the submit button state
    this.updateSubmitButton();
  }

  updateSelectAllCheckbox() {
    const allCheckboxes = document.querySelectorAll(".room-checkbox");
    const selectAllCheckbox = document.getElementById("selectAllRooms");
  
    // Check if all checkboxes are selected
    const allChecked = Array.from(allCheckboxes).every((checkbox) => checkbox.checked);
  
    // Update the "Select All" checkbox state
    selectAllCheckbox.checked = allChecked;
  
    // If any checkbox is unchecked, "Select All" should also be unchecked
    const anyUnchecked = Array.from(allCheckboxes).some((checkbox) => !checkbox.checked);
    if (anyUnchecked) {
      selectAllCheckbox.checked = false;
    }
  }

  updateSubmitButton() {
    const allCheckboxes = document.querySelectorAll(".room-checkbox");
    const submitButton = this.submitButtonTarget;
  
    // Check if any checkbox is checked
    const anyChecked = Array.from(allCheckboxes).some((checkbox) => checkbox.checked);
  
    // Enable or disable the submit button based on the state of the checkboxes
    submitButton.disabled = !anyChecked;
  }

  toggleSelectAll(event) {
    const isChecked = event.target.checked; // Check if "Select All" is checked
    const roomElements = document.querySelectorAll(".room-tab"); // Select all room elements
    const checkboxes = document.querySelectorAll(".room-checkbox"); // Select all room checkboxes
  
    roomElements.forEach((roomElement, index) => {
      const checkbox = checkboxes[index];
  
      if (isChecked) {
        // Apply styles and check the checkbox
        roomElement.classList.remove("bg-light");
        roomElement.classList.add("border", "border-dark", "bg-primary");
        checkbox.checked = true;
  
        // Update targets for the room
        const checkedRoomBox = roomElement.querySelector("[data-pmcycle-target='checkedroombox']");
        const checkedRoomText = roomElement.querySelector("[data-pmcycle-target='checkedroomtext']");
        if (checkedRoomBox) checkedRoomBox.classList.remove("d-none");
        if (checkedRoomText) {
          checkedRoomText.classList.remove("text-dark");
          checkedRoomText.classList.add("text-white");
        }
      } else {
        // Reset styles and uncheck the checkbox
        roomElement.classList.add("bg-light");
        roomElement.classList.remove("border", "border-dark", "bg-primary");
        checkbox.checked = false;
  
        // Reset targets for the room
        const checkedRoomBox = roomElement.querySelector("[data-pmcycle-target='checkedroombox']");
        const checkedRoomText = roomElement.querySelector("[data-pmcycle-target='checkedroomtext']");
        if (checkedRoomBox) checkedRoomBox.classList.add("d-none");
        if (checkedRoomText) {
          checkedRoomText.classList.add("text-dark");
          checkedRoomText.classList.remove("text-white");
        }
      }
    });

    // Update the submit button state
    this.updateSubmitButton();
  }
}
