export default class extends Controller {
  static targets = ["input", "form", "hiddenBody", "hiddenImage"];
  connect(){
    const element = document.getElementById('ai_response_output').parentElement;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
  }

  submit(event) {
    event.preventDefault(); // Prevent default form submission (we will handle it manually)
    const questionText = this.inputTarget.value.trim();
    if (questionText === "") return;

    // Create a new question div and append it immediately
    const newDiv = document.createElement("div");
    newDiv.className = "response-item";
    newDiv.innerHTML = `
      <div class="col-12 mb-3 mt-1">
        <div class="d-flex align-items-center">
          <span class="account-user-avatar">
            ${this.hiddenImageTarget.value}
          </span>
          <div class="chat-message user-message w-fit-content">${questionText}</div>
        </div>
      </div>
      <div class='loader-div' id='loader_response'>
        <div class="d-flex justify-content-center mb-1">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class='d-flex align-items-center justify-content-center'>
        <button class="btn btn-primary" type="button" disabled>
            Please wait, we are generating a response for you. This may take a moment."
        </button>
      </div>
      </div>
    `;
    document.getElementById('new-page-content')?.remove();
    document.getElementById('ai_response_output').appendChild(newDiv);
    event.currentTarget.disabled = true;

    const element = document.getElementById('ai_response_output').parentElement;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }

    // Clear the input field
    this.hiddenBodyTarget.value = questionText;
    this.inputTarget.value = "";
    // Submit the form using Turbo (this triggers the controller action in Rails)
    this.formTarget.requestSubmit();
  }

  submitForm(event){
    event.preventDefault();
    const questionText = this.inputTarget.value.trim();
    // Create a new question div and append it immediately
    const newDiv = document.createElement("div");
    newDiv.className = "response-item";
    newDiv.innerHTML = `
      <div class="col-12 mb-3 mt-1">
        <div class="d-flex align-items-center">
          <span class="account-user-avatar">
            ${this.hiddenImageTarget.value}
          </span>
          <div class="chat-message user-message w-fit-content">${questionText}</div>
        </div>
      </div>
      <div class='loader-div' id='loader_response'>
        <div class="d-flex justify-content-center mb-1">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class='d-flex align-items-center justify-content-center'>
        <button class="btn btn-primary" type="button" disabled>
            Please wait, we are generating a response for you. This may take a moment."
        </button>
        </div>
      </div>
    `;
    const parent = document.getElementById('ai_response_output');
    Array.from(parent.children).forEach(child => {
        child.style.display = "none";
    });
    document.getElementById('ai_response_output').appendChild(newDiv);
    document.getElementById('submit_button').disabled = true;
    const element = document.getElementById('ai_response_output').parentElement;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    this.formTarget.requestSubmit();
  }
}
